import React from "react";

const NewsCard = ({data}) => {

    return (
        <div className="mb-5 ">
            <div className="shadow-md border-4 rounded-xl border-white bg-[#625038] min-h-[500px] pt-[90px] px-[57px]">
               <h3
                    >
                    {data.news_name}
                </h3>
                <a target="_blank" href={data.link}>
                    <div >
                        {data.body + " (..)"}
                        <hr/>
                        --{data.medium}
                    </div>
                </a>
                <div ><a
                    target="_blank" href={data.link}>Find Out More >></a></div>
            </div>
        </div>
    );
};

export default NewsCard;
import React from 'react';
import { Link } from 'react-router-dom';
import { MetaTags } from 'react-meta-tags';
import Header from './comons/Header';
import Footer from './comons/Footer';
import { Button, Col, Container, Row } from 'react-bootstrap';
import '../assets/styles/page-area.css'


const ServicesPage = () => {
   return (
      <>
         <MetaTags>
            <meta
               name='description'
               content='Techline Communications has designed
                          and developed various Android, IOS, and web
                          applications and we have a reputation of constantly
                          adding value to organizations in Nigeria and it is
                          with this premise that we propose to development of
                          this app for your esteemed organization'
            />
            <meta property='og:title' content='Techline Communications' />
            <meta property='og:image' content='favicon.ico' />
         </MetaTags>
         <Header />
         <section className='product_business-section my-5 pb-5'>
            <Container>
               <h1 className='mt-5 mb-xl-5 mb-lg-4 mb-0 pt-lg-5 pt-0'>
                  Product and Businesses
               </h1>
               <Row>
                  <Col>
                     <div className='video-wrapper'>
                        <iframe
                            className='youtube-video'
                            src="https://www.youtube-nocookie.com/embed/msLr7iPLmv8"
                            title="YouTube video player"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                        ></iframe>
                     </div>
                  </Col>
               </Row>
               <Row className='mt-3'>
                  <Col md={6} className='mt-4'>
                     <div className='service-item mt-3 mt-xl-0'>
                        {/* <div className='icon'>
                           <i>
                              <img
                                 src='assets/images/service-icon-01.png'
                                 alt=''
                              />
                           </i>
                        </div> */}
                        <div>
                           <h6 className='service-title'>ChatGPT-Like LLM  Powered ChatBots</h6>
                           <p>
                              Unlock the potential of your business with LLM Powered ChatBot Building. Revolutionize
                              communication, enhance support, and boost productivity. Contact us today!
                           </p>
                        </div>
                        <Button
                            as={Link}
                            variant='outline-success'
                            to='/ChatGPT-Like-LLM-Service' >
                           Discover
                        </Button>
                     </div>
                  </Col>
                  <Col md={6} className='mt-4'>
                     <div className='service-item mt-3 mt-xl-0'>
                        {/* <div className='icon'>
                           <i>
                              <img
                                 src='assets/images/service-icon-02.png'
                                 alt=''
                              />
                           </i>
                        </div> */}
                        <div>
                           <h6 className='service-title'>Project Management</h6>
                           <p>
                              Unlock the potential of your business with LLM Building. Revolutionize
                              communication, enhance support, and boost productivity. Contact us today!
                           </p>
                        </div>
                        <Button
                            as={Link}
                            variant='outline-success'
                            to='/techlinemobile'
                        >
                           Discover
                        </Button>
                     </div>
                  </Col>
                  <Col md={6} className='mt-4'>
                     <div className='service-item mt-3 mt-xl-0'>
                        {/* <div className='icon'>
                           <i>
                              <img
                                 src='assets/images/service-icon-01.png'
                                 alt=''
                              />
                           </i>
                        </div> */}
                        <div>
                           <h6 className='service-title'>Techline Mobile</h6>
                           <p>
                              Techline Communications has designed and developed
                              various Android, IOS, and web applications and we
                              have a reputation of constantly adding value to
                              organizations in Nigeria and it is with this
                              premise that we propose to development of this app
                              for your esteemed organization. We have a
                              portfolio of Mobile Apps successfully built and
                              deployed to their respective app stores.
                           </p>
                        </div>
                        <Button
                           as={Link}
                           variant='outline-success'
                           to='/techlinemobile'
                        >
                           Discover
                        </Button>
                     </div>
                  </Col>
                  <Col md={6} className='mt-4'>
                     <div className='service-item mt-3 mt-xl-0'>
                        {/* <div className='icon'>
                           <i>
                              <img
                                 src='assets/images/service-icon-02.png'
                                 alt=''
                              />
                           </i>
                        </div> */}
                        <div>
                           <h6 className='service-title'>EasyTest</h6>
                           <p>
                              EasyTest Electronic Plug Play Computer-Based
                              Testing Solution automates and simplifies testing,
                              examination, and scoring processes in
                              organizations, schools, and anywhere assessments
                              are administered. It allows administrators make
                              well-informed decisions regarding candidates to be
                              assessed based on their individual test
                              performances
                           </p>
                        </div>
                        <Button
                           as={Link}
                           variant='outline-success'
                           to='/easytest'
                        >
                           Discover
                        </Button>
                     </div>
                  </Col>
                  <Col md={6} className='mt-4'>
                     <div className='service-item mt-3 mt-xl-0'>
                        {/* <div className='icon'>
                           <i>
                              <img
                                 src='assets/images/service-icon-03.png'
                                 alt=''
                              />
                           </i>
                        </div> */}
                        <div>
                           <h6 className='service-title'>SalesLine Salesbook & Inventory Management (www.salesline.click)</h6>
                           <p>
                              SalesLine Electronic Sales book and Inventory
                              management Solution eliminate paper-based
                              processes in organizations’ sales and inventory
                              management, it manages sales and expenditure
                              information and allows administrators to make
                              well-informed managerial decisions, thereby
                              automating bookkeeping processes
                           </p>
                        </div>
                        <Button
                           as={Link}
                           variant='outline-success'
                           to='/salesline'
                        >
                           Discover
                        </Button>
                     </div>
                  </Col>
                  <Col md={6} className='mt-4'>
                     <div className='service-item mt-3 mt-xl-0'>
                        {/* <div className='icon'>
                           <i>
                              <img
                                 src='assets/images/service-icon-02.png'
                                 alt=''
                              />
                           </i>
                        </div> */}
                        <div>
                           <h6 className='service-title'>HealthLine</h6>
                           <p>
                              HealthLine Hospital Management Solution eliminates
                              paper-based processes in health care
                              organizations, manages patient information, and
                              allows doctors, clinicians, and administrators to
                              make well-informed decisions in treatment and
                              hospitalization procedures. In addition, helps
                              health care organizations to automate bookkeeping,
                              patient management, etc
                           </p>
                        </div>
                        <Button
                           as={Link}
                           variant='outline-success'
                           to='/healthline'
                        >
                           Discover
                        </Button>
                     </div>
                  </Col>
                  <Col md={6} className='mt-4'>
                     <div className='service-item mt-3 mt-xl-0'>
                        {/* <div className='icon'>
                           <i>
                           <img
                                 src='assets/images/service-icon-01.png'
                                 alt=''
                                 />
                                 </i>
                        </div> */}
                        <div>
                           <h6 className='service-title'>SchollLine</h6>
                           <p>
                              School Management Solution eliminates paper-based
                              processes in educational organizations, manages
                              student information and allows administrators to
                              make well-informed decisions in school management,
                              helping them automate student management, result
                              management, bookkeeping, and other processes
                           </p>
                        </div>
                        <Button
                           as={Link}
                           variant='outline-success'
                           to='/schoolline'
                        >
                           Discover
                        </Button>
                     </div>
                  </Col>
               </Row>
            </Container>
         </section>
         <Footer />
      </>
   );
};

export default ServicesPage;

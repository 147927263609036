import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./HomePage";
import AboutPage from "./AboutPage";
import PrivacyPage from "./PrivacyPage";
import ContactPage from "./ContactPage";
import ServicesPage from "./ServicesPage";
import FrequentlyPage from "./FrequentlyPage";
import TechlineMobilePage from "./services/TechlineMobilePage";
import EasyTestPage from "./services/EasyTestPage";
import SalesLinePage from "./services/SalesLinePage";
import SchoolLinePage from "./services/SchoolLinePage";
import HealthLinePage from "./services/HealthLinePage";
import PeopleGridPage from "./services/PeopleGridPage";
import ChatGPTLikeLLMService from "./services/ChatGPTLikeLLMService";
import SalesFunnelPage from "./SalesFunnelPage";

const App = () => {

    return (
      <div className="landing">
        <Router>
          <Routes>
            <Route path="/about-us"exact element={<AboutPage />} />
            <Route path="/privacy-policy" element={<PrivacyPage />} />
            <Route path="/services" element={<ServicesPage />} />
            <Route path="/contact-us"exact element={<ContactPage />} />
            <Route path="/techlinemobile" element={<TechlineMobilePage /> } />
            <Route path="/easytest" element={<EasyTestPage /> } />
            <Route path="/salesline" element={<SalesLinePage /> } />
            <Route path="/schoolline" element={<SchoolLinePage /> } />
            <Route path="/healthline" element={<HealthLinePage /> } />
            <Route path="/frequently" element={<FrequentlyPage /> } />
            <Route path="/PeopleGrid" element={<PeopleGridPage /> } />
            <Route path="/ChatGPT-Like-LLM-Service" element={<ChatGPTLikeLLMService /> } />
            <Route path="/website" element={<SalesFunnelPage /> } />
            <Route path="/" exact element={<HomePage />} />
          </Routes>
        </Router>
      </div>
    );

};



export default App;

import React from 'react';
import { MetaTags } from 'react-meta-tags';
import Header from './comons/Header';
import Footer from './comons/Footer';
import WelcomeArea from './Components/WelcomeArea';
import SecondComp from './Components/SecondComp';
import OurServices from './Components/OurServices';
import RecentWorks from './Components/RecentWorks';
import InTheNews from "./Components/InTheNews";

const HomePage = () => {
   return (
      <div>
         <MetaTags>
            <meta
               name='description'
               content='Techline Communications has designed
                        and developed various Android, IOS, and web
                        applications and we have a reputation of constantly
                        adding value to organizations in Nigeria and it is
                        with this premise that we propose to development of
                        this app for your esteemed organization'
            />
            <meta property='og:title' content='Techline Communications' />
            <meta property='og:image' content='favicon.ico' />
         </MetaTags>
         <Header />
         <WelcomeArea />
         <SecondComp />
         <OurServices />
         <RecentWorks />
         <InTheNews />
         <Footer />
      </div>
   );
};

export default HomePage;

import React from "react";
import {MetaTags} from "react-meta-tags";
import Header from "./comons/Header";
import Footer from "./comons/Footer";

const PrivacyPage = () => {
    return (
        <div className="container-fluid">
            <MetaTags>
                <meta
                    name="description"
                    content="Techline Communications has designed
                          and developed various Android, IOS, and web
                          applications and we have a reputation of constantly
                          adding value to organizations in Nigeria and it is
                          with this premise that we propose to development of
                          this app for your esteemed organization"
                />
                <meta property="og:title" content="Techline Communications"/>
                <meta property="og:image" content="favicon.ico"/>
            </MetaTags>
            <Header/>
            <section className="section" id="about">
                <div className="container">
                    <h1>Consumer Safeline Mobile/Techline Communications/Techline Mobile Privacy Policy</h1>
                    <p><u>Introduction</u></p>
                    <p>We respect your privacy and are committed to protecting your personal information. This privacy
                        policy explains how we collect, use, protect, and share your information.</p>
                    <p><u>Contact Us</u></p>
                    <p>If you have any questions or concerns about our privacy policy, please contact our Privacy
                        Officer at <a href="mailto:policy@techlinegroup.com">policy@techlinegroup.com</a>.</p>
                    <p><u>Information We Collect</u></p>
                    <p>We do not collect any personal information from users of our apps, including emails, phone
                        numbers, or other sensitive data.</p>
                    <p><u>How We Use Your Information</u></p>
                    <p>Since we do not collect any personal information, we do not use your information for any
                        purpose.</p>
                    <p><u>Data Security</u></p>
                    <p>We implement robust security measures to protect user data, although we do not store or process
                        any personal information.</p>
                    <p><u>Data Retention and Deletion</u></p>
                    <p>We do not retain or delete any personal information since we do not collect any.</p>
                    <p><u>Sharing Your Information</u></p>
                    <p>We do not share any personal information with third parties, as we do not collect any.</p>
                    <p><u>Cookies</u></p>
                    <p>We do not use cookies for tracking purposes.</p>
                    <p><u>Third-Party Links</u></p>
                    <p>We do not include or offer third-party products or services on our website.</p>
                    <p><u>California Online Privacy Protection Act (CalOPPA)</u></p>
                    <p>We comply with CalOPPA by providing a conspicuous privacy policy, allowing users to visit our
                        site anonymously, and notifying users of any privacy policy changes.</p>
                    <p><u>Children's Online Privacy Protection Act (COPPA)</u></p>
                    <p>We do not specifically market to children under 13 years old.</p>
                    <p><u>Fair Information Practices</u></p>
                    <p>In the event of a data breach, we will notify users via email and letter within one business
                        day.</p>
                    <p><u>CAN-SPAM Act</u></p>
                    <p>We do not collect email addresses or send commercial emails.</p>
                    <p><u>Changes to This Policy</u></p>
                    <p>We may update this policy from time to time. Please review it regularly to stay informed.</p>
                    <p><u>Consent</u></p>
                    <p>By using our apps, you consent to this privacy policy. If you have any questions or concerns,
                        please contact our Privacy Officer at <a
                            href="mailto:policy@techlinegroup.com">policy@techlinegroup.com</a>.</p>
                </div>
            </section>
            <Footer/>
        </div>
    );
}


export default PrivacyPage;

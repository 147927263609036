import React from 'react';
import {Link} from 'react-router-dom';
import {MetaTags} from 'react-meta-tags';
import Header from './comons/Header';
import Footer from './comons/Footer';
import {Button, Col, Container, Row} from 'react-bootstrap';
import bannerImg1 from '../assets/images/product-school-XZkk5xT8Xrk-unsplash.jpg';
import bannerImg2 from '../assets/images/campaign-creators-qCi_MzVODoU-unsplash.jpg';
import logo1 from '../assets/images/smartphone 1.png';
import logo2 from '../assets/images/software 1.png';
import logo3 from '../assets/images/software (1) 1.png';
import logo4 from '../assets/images/project-management 1.png';

const AboutPage = () => {
    return (
        <>
            <MetaTags>
                <meta
                    name='description'
                    content='Techline Communications has designed
                          and developed various Android, IOS, and web
                          applications and we have a reputation of constantly
                          adding value to organizations in Nigeria and it is
                          with this premise that we propose to development of
                          this app for your esteemed organization'
                />
                <meta property='og:title' content='Techline Communications'/>
                <meta property='og:image' content='favicon.ico'/>
            </MetaTags>
            <Header/>
            <section className='about-section'>
                <Container>
                    <h1 className='mt-5 pt-lg-5 pt-0'>
                        We are a leading provider of comprehensive IT solutions, specializing in resolving complex
                        technological challenges
                    </h1>
                    <div className='banner-container mt-lg-5 mt-3'>
                        <div className='img-side-content left mt-lg-0 mt-4'>
                            <p>
                                We are a well-established enterprise that specializes in the creation, distribution, and
                                promotion of information-based products and services. Comprising a team of highly
                                skilled professionals, we excel in various domains, including Application Development,
                                Mobile Application Development, IT Consultancy, and Project Management.
                            </p>
                        </div>
                        <div
                            style={{
                                zIndex: '0',
                                backgroundImage: `url(${bannerImg1})`,
                            }}
                            className='banner right'
                        >
                            {/* <img src={bannerImg1} alt='banner 1' /> */}
                        </div>
                    </div>
                    <h1 className='mt-5 pt-lg-5 pt-0 second-header'>
                        Our overriding aim
                    </h1>
                    <div className='banner-container mt-lg-5 mt-3'>
                        <div
                            style={{
                                zIndex: '0',
                                // backgroundImage: `url(${bannerImg2})`,
                            }}
                            className='banner left'
                        >
                            <img src={bannerImg2} alt='banner 2'/>
                        </div>
                        <div className='img-side-content right mt-lg-0 mt-4'>
                            <p>

                                Our primary objective is to empower clients to capitalize on the full potential of
                                available information technologies for commercial benefit. Service Excellence is of
                                paramount importance to us, as we recognize the pivotal role quality plays in all
                                aspects of our operations. <br/>Our unwavering dedication to service quality excellence
                                serves as the cornerstone of our endeavors, and our workforce comprises highly skilled
                                and proficient experts in their respective fields.

                            </p>
                        </div>
                    </div>
                    <Row className='mt-5 pt-lg-4 pt-0'>
                        <Col md={6} className='mt-4'>
                            <div className='serv-item mt-3 mt-xl-0'>
                                <div className='serv-logo  text-center'>
                                    <img src={logo1} alt='icon'/>
                                </div>
                                <h6 className='mt-3 text-center'>
                                    Mobile Application Development
                                </h6>
                                <small className='mt-3 text-center'>
                                    has designed and developed various Android, IOS, and
                                    web applications and we have a reputation of
                                    constantly adding value to organizations in Nigeria
                                    and it is with this premise that we propose to
                                    development of this app for your esteemed
                                    organization
                                </small>
                            </div>
                        </Col>
                        <Col md={6} className='mt-4'>
                            <div className='serv-item mt-3 mt-xl-0'>
                                <div className='serv-logo  text-center'>
                                    <img src={logo2} alt='icon'/>
                                </div>
                                <h6 className='mt-3 text-center'>
                                    Software Development
                                </h6>
                                <small className='mt-3 text-center'>
                                    We are also in the business of conceiving,
                                    specifying, designing, programming, documenting,
                                    testing, bug fixing applications, frameworks, or
                                    other software components for corporate and
                                    individual clients.
                                </small>
                            </div>
                        </Col>
                        <Col md={6} className='mt-4'>
                            <div className='serv-item mt-3 mt-xl-0'>
                                <div className='serv-logo  text-center'>
                                    <img src={logo3} alt='icon'/>
                                </div>
                                <h6 className='mt-3 text-center'>IT Consultancy</h6>
                                <small className='mt-3 text-center'>
                                    The role of the IT consultancy company is to support
                                    and nurture the company from the very beginning of
                                    the project until the end, and deliver the project
                                    not only in the scope, time and cost but also with
                                    complete customer satisfaction.
                                </small>
                            </div>
                        </Col>
                        <Col md={6} className='mt-4'>
                            <div className='serv-item mt-3 mt-xl-0'>
                                <div className='serv-logo  text-center'>
                                    <img src={logo4} alt='icon'/>
                                </div>
                                <h6 className='mt-3 text-center'>Project Management</h6>
                                <small className='mt-3 text-center'>
                                    the process of leading the work of a team to achieve
                                    goals and meet success criteria at a specified time.
                                    The primary challenge of project management is to
                                    achieve all of the project goals within the given
                                    constraints. We can help you with this.
                                </small>
                            </div>
                        </Col>
                    </Row>
                    <div className='contact-pane mt-5'>
                        <h6>Questions/Comments/Suggestions</h6>
                        <small>If you have any question or comment, please</small>
                        <Button
                            as={Link}
                            to='/contact-us'
                            size='lg'
                            className='mt-2 mt-lg-4'
                        >
                            Contact Now
                        </Button>
                    </div>
                </Container>
            </section>
            {/* <section className="section" id="about">
          <div className="container">
            <div className="row">
            <div
                className="col-lg-7 col-md-12 col-sm-12"
                data-scroll-reveal="enter left move 30px over 0.6s after 0.4s"
              >
                <img
                  src="assets/images/left-image.png"
                  className="rounded img-fluid d-block mx-auto"
                  alt="App"
                ></img>
              </div>
              <div className="right-text col-lg-5 col-md-12 col-sm-12 mobile-top-fix">
                <div className="left-heading">
                  <h5>We Are</h5>
                </div>
                <div className="left-text">
                  <p>
                    a company that focuses on the production, sales and
                    marketing of information products and services.
                    <br />
                    We are a team of talented individuals with first class
                    skills in:
                    <ul>
                      <li>Application Development</li>
                      <li>Mobile Application Development</li>
                      <li>IT Consultancy</li>
                      <li>Project management</li>
                    </ul>
                  </p>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="hr"></div>
                  </div>
                </div>
              </div>

              <div className="container">
                <div className="row">
                  <div className="left-text col-lg-5 col-md-12 col-sm-12 mobile-bottom-fix">
                    <div className="left-heading">
                      <h5> Our overriding aim </h5>
                    </div>
                    <p>
                      is to enable clients to take maximum commercial advantage
                      of available information technologies.
                    </p>
                    <p>
                      <strong>Service Excellence</strong>
                      When rendering any service, it is important to know that
                      quality matters. Our commitment to service quality
                      excellence underpins everything we do, and all our staff
                      are experts and specialists at what they do
                    </p>
                    <ul>
                      <li>
                        <img src="assets/images/about-icon-01.png" alt=""></img>
                        <div className="text">
                          <h6>Mobile Application Development</h6>
                          <p>
                            <strong>Techline Communications</strong> has
                            designed and developed various Android, IOS, and web
                            applications and we have a reputation of constantly
                            adding value to organizations in Nigeria and it is
                            with this premise that we propose to development of
                            this app for your esteemed organization
                          </p>
                        </div>
                      </li>
                      <li>
                        <img src="assets/images/about-icon-02.png" alt=""></img>
                        <div className="text">
                          <h6>Software Development</h6>
                          <p>
                            We are also in the business of conceiving,
                            specifying, designing, programming, documenting,
                            testing, bug fixing applications, frameworks, or
                            other software pages for corporate and
                            individual clients.
                          </p>
                        </div>
                      </li>
                      <li>
                        <img src="assets/images/about-icon-03.png" alt=""></img>
                        <div className="text">
                          <h6>IT Consultancy</h6>
                          <p>
                            The role of the IT consultancy company is to support
                            and nurture the company from the very beginning of
                            the project until the end, and deliver the project
                            not only in the scope, time and cost but also with
                            complete customer satisfaction.
                          </p>
                        </div>
                      </li>
                      <li>
                        <img src="assets/images/about-icon-01.png" alt=""></img>
                        <div className="text">
                          <h6>Project Management</h6>
                          <p>
                            the process of leading the work of a team to achieve
                            goals and meet success criteria at a specified time.
                            The primary challenge of project management is to
                            achieve all of the project goals within the given
                            constraints. We can help you with this.
                          </p>
                        </div>
                      </li>
                      <li>
                        <img src="assets/images/about-icon-03.png" alt=""></img>
                        <div className="text">
                          <h6>Questions/Comments/Suggestions</h6>
                          <p>
                            If you have any question or comment, please{" "}
                            <Link rel="nofollow" to="/contact-us">
                              contact
                            </Link>
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div
                    className="right-image col-lg-7 col-md-12 col-sm-12 mobile-bottom-fix-big"
                    data-scroll-reveal="enter right move 30px over 0.6s after 0.4s"
                  >
                    <img
                      src="assets/images/right-image.png"
                      className="rounded img-fluid d-block mx-auto"
                      alt="App"
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
            <Footer/>
        </>
    );
};

export default AboutPage;

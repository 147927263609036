import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Container, Nav, Navbar } from 'react-bootstrap';
import mainLogo from '../../assets/images/techline_logo (1) 1.png';

const Header = () => {
   return (
      <>
         {/* <header className='header-area header-sticky'>
            <div className='container'>
               <div className='row'>
                  <div className='col-12'> */}
         {/* <nav className="main-nav">
              <Link to="/" className="logo" >
                Techline
              </Link>

              <ul className="nav">
                <li className="scroll-to-section">
                  <Link to="/" className="active">
                    Home
                  </Link>
                </li>
                <li className="scroll-to-section">
                  <Link to="/about-us" className="active">
                    About
                  </Link>
                </li>
                <li className="scroll-to-section">
                  <Link to="/services" className="active">
                    Products &amp; Businesses
                  </Link>
                </li>
                <li className="scroll-to-section">
                  <Link to="/contact-us" className="active">
                    Contact us
                  </Link>
                </li>
              </ul>
              <a className="menu-trigger">
                <span>Menu</span>
              </a>
            </nav> */}
         {/* </div>
               </div>
            </div>
         </header> */}
         <Navbar
            expand='lg'
            className='d-flex justify-content-between topbar'
            sticky='top'
            style={{ backgroundColor: '#fff' }}
         >
            <Container>
               <Navbar.Brand className='d-lg-none' as={Link} to='/'>
                  <img src={mainLogo} alt='Logo' />
               </Navbar.Brand>
               <Navbar.Toggle aria-controls='basic-navbar-nav' />
               <Navbar.Collapse id='basic-navbar-nav' style={{ flexGrow: '0' }}>
                  <Nav className='me-auto'>
                     <Nav.Link as={NavLink} to='/'>
                        Home
                     </Nav.Link>
                     <Nav.Link as={NavLink} to='/about-us'>
                        About
                     </Nav.Link>
                     <Nav.Link as={NavLink} to='/services'>
                        Products &amp; Businesses
                     </Nav.Link>
                     <Nav.Link as={NavLink} to='/contact-us'>
                        Contact us
                     </Nav.Link>
                  </Nav>
               </Navbar.Collapse>
               <Navbar.Brand className='d-none d-lg-block' as={Link} to='/'>
                  <img src={mainLogo} alt='Logo' />
               </Navbar.Brand>
               <div className='header-mail d-xl-block d-none'>
                  Email: info@techlinegroup.com
               </div>
            </Container>
         </Navbar>
      </>
   );
};

export default Header;

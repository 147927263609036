import React from 'react';

const Footer = () => {
    const year = new Date().getFullYear();
   return (
      <>
         <footer className='mt-5'>
            <div className='container'>
               <div className='row'>
                  <div className='col-12'>
                     <p className='copyright'>
                        Copyright © 2010 - {year} Techline Communications
                     </p>
                     {/* <p className="copyright">Copyright &copy; 2021 Techline Communications
                </p> */}
                  </div>
                  {/* <div className='col-lg-5 col-md-12 col-sm-12'>
                      <ul className="social">
                        <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                        <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                        <li><a href="#"><i className="fa fa-linkedin"></i></a></li>
                        <li><a href="#"><i className="fa fa-rss"></i></a></li>
                        <li><a href="#"><i className="fa fa-dribbble"></i></a></li>
                    </ul>
                  </div> */}
               </div>
            </div>
         </footer>

         <script src='assets/js/jquery-2.1.0.min.js'></script>

         <script src='assets/js/popper.js'></script>
         {/* <script src='assets/js/bootstrap.min.js'></script> */}

         <script src='assets/js/owl-carousel.js'></script>
         <script src='assets/js/scrollreveal.min.js'></script>
         <script src='assets/js/waypoints.min.js'></script>
         <script src='assets/js/jquery.counterup.min.js'></script>
         <script src='assets/js/imgfix.min.js'></script>
         <script src='assets/js/custom.js'></script>
      </>
   );
};

export default Footer;

import React from "react";
import { Link } from "react-router-dom";
import { MetaTags } from "react-meta-tags";
import Header from "../comons/Header";
import Footer from "../comons/Footer";

const EasyTestPage = () => {
    return (
      <div className="container-fluid">
        <MetaTags>
          <meta
            name="description"
            content="Techline Communications has designed
                          and developed various Android, IOS, and web
                          applications and we have a reputation of constantly
                          adding value to organizations in Nigeria and it is
                          with this premise that we propose to development of
                          this app for your esteemed organization"
          />
          <meta property="og:title" content="Techline Communications" />
          <meta property="og:image" content="favicon.ico" />
        </MetaTags>
        <Header />
        <section className="section" id="about">
          <div className="container">
            <div className="row">
              <div className="container">
                <div className="row">
                  <div className="left-text col-lg-5 col-md-12 col-sm-12 mobile-bottom-fix">
                    <div className="left-heading">
                      <h5> EasyTest</h5>
                    </div>
                    <p>
                      EasyTest Electronic Plug Play Computer-Based Testing
                      Solution automates and simplifies testing, examination,
                      and scoring processes in organizations, schools, and
                      anywhere assessments are administered. It allows
                      administrators make well-informed decisions regarding
                      candidates to be assessed based on their individual test
                      performances Its is useful for primary, secondary and
                      tertiary institutions like schools as well as corporate
                      organizations where individual skill assessments are
                      carried out It is easy to use and very enjoyable
                      <br />
                      Features
                      <ul>
                        <li>
                          Simply upload the questions to the application and the
                          application does the rest.
                        </li>
                      </ul>
                      <ul>
                        <li>Simple, Powerful &amp; Affordable</li>
                      </ul>
                      <ul>
                        <li>
                          100% offline(
                          <strong>use without internet&nbsp;)</strong>
                        </li>
                        <li>
                          Useful for all corporate Organisations, schools and
                          other establishma=ents
                        </li>
                        <li>EasyTest Information management</li>
                      </ul>
                    </p>
                    <Link to="/contact-us" className="main-button">
                      Discover More
                    </Link>
                  </div>
                  <div
                    className="right-image col-lg-7 col-md-12 col-sm-12 mobile-bottom-fix-big"
                    data-scroll-reveal="enter right move 30px over 0.6s after 0.4s"
                  >
                    <img
                      src="assets/images/right-image.png"
                      className="rounded img-fluid d-block mx-auto"
                      alt="App"
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }

export default EasyTestPage;

export const SALESLINE_BASE_URL = "https://gf7mtukyyk.us-east-1.awsapprunner.com/user"

export const CONTACT_API_URL =
    'https://17o5g7wjd1.execute-api.us-east-1.amazonaws.com/main/contactus/'

export const SEND_WEB_FUNNEL_EMAIL_URL =
    SALESLINE_BASE_URL + '/mail/sendWebsiteSalesFunnelMail';

export const GET_ALL_ARTICLES_URL =
    SALESLINE_BASE_URL + "/news";


import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './welcome-area.css'

const WelcomeArea = () => {
    return (
        <div className='container mt-4'>
            <Row>
                <Col
                    md={6}
                    data-scroll-reveal='enter left move 30px over 0.6s after 0.4s'
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'center',
                    }}
                >
                    <div className='welcome-content'>
                        <h1>Transform Your Business with ChatGPT-Like LLM Powered ChatBots</h1>
                        <p className='mt-2 mt-lg-4'>
                            Unlock the potential of your business with LLM Powered ChatBots. Revolutionize communication, enhance support, and boost productivity. Contact us today!
                        </p>
                        <Button
                            as={Link}
                            to='/ChatGPT-Like-LLM-Service'
                            size='lg'
                            className='mt-2 mt-lg-4'
                        >
                            GET STARTED
                        </Button>
                    </div>
                </Col>
                <Col
                    md={6}
                    data-scroll-reveal='enter left move 30px over 0.6s after 0.4s'
                >
                    <div className='video-wrapper'>
                        <iframe
                            className='youtube-video'
                            src="https://www.youtube-nocookie.com/embed/o2G0TbwB9XQ"
                            title="YouTube video player"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                        ></iframe>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default WelcomeArea;

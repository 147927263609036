import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import logo1 from '../../assets/images/smartphone 1.png';
import logo2 from '../../assets/images/software 1.png';
import logo3 from '../../assets/images/software (1) 1.png';
import logo4 from '../../assets/images/project-management 1.png';

const OurServices = () => {
    return (
        <div className='services-section mt-3 mt-lg-5 pt-3 pt-lg-5'>
            <Container>
                <div className='services-top'>
                    <h1>OUR SERVICES</h1>
                    <h5 className='mt-2 mt-lg-4'>
                        When rendering any service, it is important to know that
                        quality matters. Our commitment to service quality excellence
                        underpins everything we do, and all our staff are experts and
                        specialists at what they do
                    </h5>
                </div>
                <Row className='services-bottom mt-3 mt-lg-5'>
                    <Col xl={3} sm={6} className='d-flex justify-content-center'>
                        <div className='serv-item mt-3 mt-xl-0'>
                            <div className='serv-logo'>
                                <img
                                    src={logo1}
                                    alt='icon'
                                    width='50px'
                                    height='50px'
                                />
                            </div>
                            <h6 className='mt-3'>Mobile Application Development</h6>
                            <small className='mt-3'>
                                We have a reputation for consistently bringing value to enterprises in Nigeria thanks to
                                the team's experience designing and developing a variety of Android, iOS, and web
                                applications. It is on the basis of this premise that we propose to construct this app
                                for your valued organization.
                            </small>
                        </div>
                    </Col>
                    <Col xl={3} sm={6} className='d-flex justify-content-center'>
                        <div className='serv-item mt-3 mt-xl-0'>
                            <div className='serv-logo'>
                                <img
                                    src={logo2}
                                    alt='icon'
                                    width='50px'
                                    height='50px'
                                />
                            </div>
                            <h6 className='mt-3'>Software Development</h6>
                            <small className='mt-3'>
                                We are also in the business of conceiving, specifying,
                                designing, programming, documenting, testing, bug fixing
                                applications, frameworks, or other software components
                                for corporate and individual clients.
                            </small>
                        </div>
                    </Col>
                    <Col xl={3} sm={6} className='d-flex justify-content-center'>
                        <div className='serv-item mt-3 mt-xl-0'>
                            <div className='serv-logo'>
                                <img
                                    src={logo3}
                                    alt='icon'
                                    width='50px'
                                    height='50px'
                                />
                            </div>
                            <h6 className='mt-3'>IT Consultancy</h6>
                            <small className='mt-3'>
                                From the very beginning of the project until the very conclusion, the IT consulting
                                firm's responsibility is to support and nurture the business and to deliver the project
                                not only within the project's scope, timeframe, and budget, but also with full customer
                                satisfaction.
                            </small>
                        </div>
                    </Col>
                    <Col xl={3} sm={6} className='d-flex justify-content-center'>
                        <div className='serv-item mt-3 mt-xl-0'>
                            <div className='serv-logo'>
                                <img
                                    src={logo4}
                                    alt='icon'
                                    width='50px'
                                    height='50px'
                                />
                            </div>
                            <h6 className='mt-3'>Project Management</h6>
                            <small className='mt-3'>
                                the practice of overseeing a team's work such that objectives are met and success
                                standards are met at a specific period. To complete all project objectives within the
                                restrictions imposed is the main difficulty of project management.
                                You can get our assistance here.
                            </small>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default OurServices;

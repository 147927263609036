import React from 'react';
import {Container} from 'react-bootstrap';
import bannerBG from '../../assets/images/product-school-XZkk5xT8Xrk-unsplash.jpg';

const SecondComp = () => {
    return (
        <div className='second-section mt-5'>
            <Container>
                <h1>We are the solution for IT problems</h1>
            </Container>
            <div
                className='banner mt-4'
                style={{
                    background: `linear-gradient(0deg, rgba(0,0,0,0.7), rgba(0,0,0,0.7)), url(${bannerBG})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    backgroundRepeat: 'no-repeat',
                }}
            >
                <Container>
                    <p>
                        "Unlock the power of information with our talented team specializing in application development,
                        mobile app development, IT consultancy, and project management. Elevate your business with our
                        cutting-edge information products and services."
                    </p>
                </Container>
            </div>
        </div>
    );
};

export default SecondComp;

import React from 'react';
import { Link } from 'react-router-dom';
import { MetaTags } from 'react-meta-tags';
import Header from '../comons/Header';
import Footer from '../comons/Footer';
import {Button, Col, Container, Row} from 'react-bootstrap';

const TechlineMobilePage = () => {
   return (
      <div className='container-fluid'>
         <MetaTags>
            <meta
               name='description'
               content='Techline Communications has designed
                          and developed various Android, IOS, and web
                          applications and we have a reputation of constantly
                          adding value to organizations in Nigeria and it is
                          with this premise that we propose to development of
                          this app for your esteemed organization'
            />
            <meta property='og:title' content='Techline Communications' />
            <meta property='og:image' content='favicon.ico' />
         </MetaTags>
         <Header />
         <section className='service-details-page'>
            <Container>
               <h1> Techline Mobile </h1>
               <div className='details'>
                  <h6>Techline Communications</h6>
                  <Row>
                     <Col>
                        <div className='video-wrapper'>
                           <iframe
                               className='youtube-video'
                               src="https://www.youtube-nocookie.com/embed/msLr7iPLmv8"
                               title="YouTube video player"
                               allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                               allowFullScreen
                           ></iframe>
                        </div>
                     </Col>
                  </Row>
                  <p>
                     has designed and developed various Android, IOS, and web
                     applications and we have a reputation of constantly adding
                     value to organizations in Nigeria and it is with this
                     premise that we propose to development of this app for your
                     esteemed organization We develop apps so you don&rsquo;t
                     have to We are living in the age of connectivity, and that
                     means more things than ever before are right at your
                     fingertips — literally. With one press of the button, one
                     swipe left or right, you can open up new worlds in seconds.
                     We&rsquo;re talking about apps, those little icons on your
                     mobile device.
                  </p>
                  {/* <p>
                     <br />
                     But at <strong>Techline Communications</strong>
                     , we don&rsquo;t just talk about apps; we live and breathe
                     apps. We have assembled a team of the best and brightest
                     minds in software development, marketing, and leadership,
                     giving our clients access to the most cutting-edge
                     technology. You can rest assured you&rsquo;re in good
                     hands, as we have years of experience in both Android and
                     iOS app development.
                     <br />
                     Our goal is to create something you&rsquo;re proud of and
                     that helps your business. Apps can be transcendent, and
                     they can also be colossal failures. That&rsquo;s why
                     Techline Communications has developed a comprehensive
                     approach to app development that takes the guessing out of
                     the game. We&rsquo;re ecstatic that you&rsquo;re
                     considering doing business with us, so let&rsquo;s get
                     started.
                  </p> */}
                  <h6>What to Expect</h6>
                  <p>
                     Design Mobile app development is serious business. It takes
                     time and patience to create something that works for you
                     and is free of bugs and other issues. Updates are required,
                     but it&rsquo;s important to start with a sound foundation.
                     At Techline Communications, we believe in a thorough
                     approach that provides our clients with as much engagement
                     as they request. While our entire team will be developing
                     your app, we will assign a project lead who will be your
                     main point of contact.
                  </p>
                  <h6>Research and outreach</h6>
                  <p>
                     A large part of the work Techline Communications does is
                     behind the scenes. There will be times when we don&rsquo;t
                     communicate with You for weeks, but that&rsquo;s only
                     because we&rsquo;re intimately involved in the development
                     phase. However, before any of that begins, we need to make
                     a checklist of everything you want in your new mobile app.
                     We will gather information about your company and how it
                     works. We will figure out who your customers are and how we
                     can attract more through your new app. Audience engagement,
                     research, and branding are key in app development, and we
                     will conduct focus groups to find out why people choose
                     You.
                  </p>
                  <h6>Framework</h6>
                  <p>
                     Like a website, an app needs a sitemap and wireframes.
                     Think of this as the structural integrity of a skyscraper.
                     You will have an important role in the design process, as
                     it&rsquo;s important that you are getting what you want.
                     Plus, it&rsquo;s better to work out issues in this stage
                     than later down the road. Here are some highlights of this
                     process:
                     <br /> &bull; Functionality and content
                     <br /> &bull; Wireframes, the structural core of your app
                     <br /> &bull; Branding and integration of existing digital
                     platforms (i.e., web and email)
                     <br /> &bull; User Experience and User Interface, or UX and
                     UI &mdash; essentially, how you interact with the app, what
                     makes it easy to use and desirable
                  </p>
                  <h6>Development</h6>
                  <p>
                     We&rsquo;ve come a long way at this point. You&rsquo;ve put
                     in a lot of work, so now you can step aside and let us do
                     the rest. This part of the process is laborious and
                     time-consuming, so don&rsquo;t expect to hear from us much.
                     But that&rsquo;s a good thing, as we&rsquo;ve worked out
                     all the kinks and come to an understanding about everything
                     that will go into your new app. Once development is
                     complete, You will be back in the fold for the testing
                     trials. Testing and launch We&rsquo;re almost there. Your
                     new mobile app is built and ready to launch. But before
                     that happens, Techline Communications and You need to
                     collaborate on a marketing strategy. After all, just
                     because you invested all this time and money into your new
                     app, it doesn&rsquo;t mean anyone will know it exists
                     unless we tell them. Timeline Techline Communications
                     estimates that it will take 2 to 4 months to complete your
                     new mobile app. Upon signing this agreement, we can begin
                     immediately. Here&rsquo;s what to expect:
                  </p>
                  <h6>Expenses</h6>
                  <p>
                     We want You to receive the utmost value from your
                     investment in a new mobile app. This budget breakdown is
                     based on the project outline described above. Please
                     contact your project lead with any issues or questions
                     before signing.
                  </p>
                  {/* <div className='btn-center-container'> */}
                  <Button as={Link} to='/contact-us' variant='success'>
                     Discover More
                  </Button>
                  {/* </div> */}
               </div>

               {/* </div> */}
               {/* <div
                           className='right-image col-lg-7 col-md-12 col-sm-12 mobile-bottom-fix-big'
                           data-scroll-reveal='enter right move 30px over 0.6s after 0.4s'
                        >
                           <img
                              src='assets/images/right-image.png'
                              className='rounded img-fluid d-block mx-auto'
                              alt='App'
                           ></img>
                        </div> */}
            </Container>
         </section>
         <Footer />
      </div>
   );
};

export default TechlineMobilePage;

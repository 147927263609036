import React from "react";
import { Link } from "react-router-dom";
import { MetaTags } from "react-meta-tags";
import Header from "../comons/Header";
import Footer from "../comons/Footer";

const HealthLinePage = () => {
    return (
      <div className="container-fluid">
        <MetaTags>
          <meta
            name="description"
            content="Techline Communications has designed
                          and developed various Android, IOS, and web
                          applications and we have a reputation of constantly
                          adding value to organizations in Nigeria and it is
                          with this premise that we propose to development of
                          this app for your esteemed organization"
          />
          <meta property="og:title" content="Techline Communications" />
          <meta property="og:image" content="favicon.ico" />
        </MetaTags>
        <Header />
        <section className="section" id="about">
          <div className="container">
            <div className="row">
              <div className="container">
                <div className="row">
                  <div className="left-text col-lg-5 col-md-12 col-sm-12 mobile-bottom-fix">
                    <div className="left-heading">
                      <h5> HealthLine </h5>
                    </div>
                    <p>
                      HealthLine Hospital Management Solution eliminates
                      paper-based processes in health care organizations,
                      manages patient information, and allows doctors,
                      clinicians, and administrators to make well-informed
                      decisions in treatment and hospitalization procedures. In
                      addition, helps health care organizations to automate
                      bookkeeping, patient management, etc
                    </p>
                    <ul>
                      <li>Patient dashboard to view patient history.</li>
                      <li>handle a large number of patients efficiently.</li>
                      <li>Report Generation</li>
                      <li>
                        Track, Manage and Optimize Financial and Administrative
                        Processes
                      </li>
                      <li>
                        Eliminate delays inpatient discharge and simplify
                        billing
                      </li>
                      <li>Simple, Powerful &amp; Affordable</li>
                      <li>
                        Improve patient outcomes with consolidated patient data
                        on a central platform
                      </li>
                      <li>
                        designed to cover hospital management without any
                        paperwork.
                      </li>
                      <li>
                        100% offline(
                        <strong>use without internet&nbsp;)</strong>
                      </li>
                    </ul>
                    Increase your revenues and profits Measure and optimize to
                    create better &nbsp;strategies and tactics using standard
                    &nbsp;Information system and standard reports HealthLine’s
                    Simple and user-friendly features allow new users to quickly
                    start using the software with ease.
                    <Link to="/contact-us" className="main-button">
                      Discover More
                    </Link>
                  </div>
                  <div
                    className="right-image col-lg-7 col-md-12 col-sm-12 mobile-bottom-fix-big"
                    data-scroll-reveal="enter right move 30px over 0.6s after 0.4s"
                  >
                    <img
                      src="assets/images/right-image.png"
                      className="rounded img-fluid d-block mx-auto"
                      alt="App"
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }

export default HealthLinePage;

import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';

const ContactForm = (props) => {
   return (
      <>
         <form id='contact' onSubmit={props.onSubmit}>
            <Row>
               <Col md={6}>
                  <fieldset>
                     <Form.Label>Full Name</Form.Label>
                     <input
                        name='name'
                        type='text'
                        id='name'
                        placeholder='Enter your name...'
                        onChange={props.onNameChange}
                        required
                        className='form-control'
                        value={props.contactMessage.userfullname}
                     />
                  </fieldset>
               </Col>
               <Col md={6}>
                  <fieldset>
                     <Form.Label>Email Address</Form.Label>
                     <input
                        name='email'
                        type='text'
                        id='email'
                        placeholder='Enter your Email'
                        onChange={props.onEmailChange}
                        required
                        className='form-control'
                        value={props.contactMessage.useremail}
                     />
                  </fieldset>
               </Col>
               <Col xs={12}>
                  <fieldset>
                     <Form.Label>Messages</Form.Label>
                     <textarea
                        name='message'
                        rows='6'
                        id='message'
                        placeholder='Enter your messages...'
                        onChange={props.onMessageChange}
                        required
                        value={props.contactMessage.usermessage}
                        className='form-control'
                     ></textarea>
                  </fieldset>
               </Col>
               <Col>
                  <button
                     type='submit'
                     id='form-submit'
                     className='form-submit'
                  >
                     Submit
                  </button>
               </Col>
            </Row>
         </form>
      </>
   );
};

export default ContactForm;

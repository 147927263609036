import React from "react";
import { MetaTags } from "react-meta-tags";
import Header from "../comons/Header";
import Footer from "../comons/Footer";

const SalesLinePage = () => {
    return (
      <div className="container-fluid">
        <MetaTags>
          <meta
            name="description"
            content="Techline Communications has designed
                          and developed various Android, IOS, and web
                          applications and we have a reputation of constantly
                          adding value to organizations in Nigeria and it is
                          with this premise that we propose to development of
                          this app for your esteemed organization"
          />
          <meta property="og:title" content="Techline Communications" />
          <meta property="og:image" content="favicon.ico" />
        </MetaTags>
        <Header />
        <section className="section" id="about">
          <div className="container">
            <div className="row">
              <div className="container">
                <div className="row">
                  <div className="left-text col-lg-5 col-md-12 col-sm-12 mobile-bottom-fix">
                    <div className="left-heading">
                      <h5> SalesLine </h5>
                    </div>
                    <p>
                      SalesLine Electronic Sales book and Inventory management
                      Solution eliminate paper-based processes in organizations’
                      sales and inventory management, it manages sales and
                      expenditure information and allows administrators to make
                      well-informed managerial decisions, thereby automating
                      bookkeeping processes Features
                      <ul>
                        <li>Inventory management</li>
                        <li>Customer management</li>
                        <li>Retail Accounting</li>
                        <li>Suitable for businesses of all sizes</li>
                        <li>Simple, Powerful &amp; Affordable</li>
                        <li>Report generation</li>
                        <li>Contact Management</li>
                        <li>Income and Expense Management</li>
                        <li>Product Information management</li>
                        <li>100% offline (use without internet)</li>
                      </ul>
                    </p>
                    <a href="https://salesline.click"
                       rel="noopener"
                       target="_blank" className="main-button">
                      Discover More
                    </a>
                  </div>
                  <div
                    className="right-image col-lg-7 col-md-12 col-sm-12 mobile-bottom-fix-big"
                    data-scroll-reveal="enter right move 30px over 0.6s after 0.4s"
                  >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }

export default SalesLinePage;

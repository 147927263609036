import React from "react";
import { Link } from "react-router-dom";
import { MetaTags } from "react-meta-tags";
import Header from "../comons/Header";
import Footer from "../comons/Footer";

const SchoolLinePage = () => {
    return (
      <div className="container-fluid">
        <MetaTags>
          <meta
            name="description"
            content="Techline Communications has designed
                          and developed various Android, IOS, and web
                          applications and we have a reputation of constantly
                          adding value to organizations in Nigeria and it is
                          with this premise that we propose to development of
                          this app for your esteemed organization"
          />
          <meta property="og:title" content="Techline Communications" />
          <meta property="og:image" content="favicon.ico" />
        </MetaTags>
        <Header />
        <section className="section" id="about">
          <div className="container">
            <div className="row">
              <div className="container">
                <div className="row">
                  <div className="left-text col-lg-5 col-md-12 col-sm-12 mobile-bottom-fix">
                    <div className="left-heading">
                      <h5> SchoolLine </h5>
                    </div>
                    <p>
                      School Management Solution eliminates paper-based
                      processes in educational organizations, manages student
                      information and allows administrators to make
                      well-informed decisions in school management, helping them
                      automate student management, result management,
                      bookkeeping, and other processes
                      <br />
                      Features
                      <ul>
                        <li>Simple, Powerful &amp; Affordable</li>
                        <li>
                          100% offline(
                          <strong>use without internet&nbsp;)</strong>
                        </li>
                        <li>Student Admission Management</li>
                        <li>Income and Expense &nbsp;Management</li>
                        <li>Student Information management</li>
                        <li>Gradebook/Result compilation management</li>
                        <li>Teacher Management</li>
                      </ul>
                      SchoolLine Improves managerial efficiency and gives you
                      more time to focus on what is really important, the
                      student. Forget sorting through bulky file cabinets. With
                      SchoolLine all records, even those from decades ago, are
                      instantly accessible. It prevents errors/fraud and saves
                      costs associated with these issues.
                      <ul>
                        <li>
                          speed up the compilation of results in Primary /
                          Secondary Schools.
                        </li>
                      </ul>
                    </p>
                    <Link to="/contact-us" className="main-button">
                      Discover More
                    </Link>
                  </div>
                  <div
                    className="right-image col-lg-7 col-md-12 col-sm-12 mobile-bottom-fix-big"
                    data-scroll-reveal="enter right move 30px over 0.6s after 0.4s"
                  >
                    <img
                      src="assets/images/right-image.png"
                      className="rounded img-fluid d-block mx-auto"
                      alt="App"
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }


export default SchoolLinePage;

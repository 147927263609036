import React from "react";
import { MetaTags } from "react-meta-tags";
import Header from "../comons/Header";
import Footer from "../comons/Footer";

const PeopleGridPage = () => {
    return (
      <div className="container-fluid">
        <MetaTags>
          <meta
            name="description"
            content="Techline Communications has designed
                          and developed various Android, IOS, and web
                          applications and we have a reputation of constantly
                          adding value to organizations in Nigeria and it is
                          with this premise that we propose to development of
                          this app for your esteemed organization"
          />
          <meta property="og:title" content="Techline Communications" />
          <meta property="og:image" content="favicon.ico" />
        </MetaTags>
        <Header />
        <section className="section" id="about">
          <div className="container">
            <div className="row">
              <div className="container">
                <div className="row">
                  <div className="left-text col-lg-5 col-md-12 col-sm-12 mobile-bottom-fix">
                    <div className="left-heading">
                      <h5> PeopleGrid </h5>
                    </div>
                    <p>
                      PeopleGrid Electronic Human Resource & Account management Solution eliminate paper-based
                      procedures in businesses It is a collection of several different pieces of software that
                      enables managers to make informed choices, automating bookkeeping procedures.

                      <ul>
                        <li>Human Resource management</li>
                        <li> Onboarding software</li>
                        <li>learning</li>
                        <li>Retail Accounting</li>
                        <li>Suitable for businesses of all sizes</li>
                        <li>Simple, Powerful & Affordable</li>
                        <li>Report generation</li>
                        <li>Contact Management</li>
                        <li>Income and Expense Management</li>
                        <li>Product Information management</li>

                      </ul>
                    </p>
                    <a href="https://peoplegrid.click" target="_blank" className="main-button">
                      Discover More
                    </a>
                  </div>
                  <div
                    className="right-image col-lg-7 col-md-12 col-sm-12 mobile-bottom-fix-big"
                    data-scroll-reveal="enter right move 30px over 0.6s after 0.4s"
                  >

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }

export default PeopleGridPage;

import React, {useEffect, useState} from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {GET_ALL_ARTICLES_URL} from "../../utils/constants";
import axios from "axios";
import NewsCard from "./NewsCard";

const InTheNews = () => {
   const [loading, setLoading] = useState(false);
   const [allNewsArticles, setAllNewsArticles] = useState([]);


   useEffect(() => {
      (async () => {
         try {
            setLoading(true);
            const { data } = await axios.get(GET_ALL_ARTICLES_URL);
            setAllNewsArticles(data);
            setLoading(false);
            console.log("LOADING IS FALSEEEEE")

         } catch (err) {
            setLoading(false);
         }
      })();
   }, []);
   return (
      <div className='recent-works-section mt-5'>
         <Container>
           <Row>
              <div className="container mx-auto py-20">
              <h1 className="text-black text-center mb-20 font-[Roboto] font-bold text-[32px] md:text-[50px] md:leading-[30px]">
                 In The News
              </h1>
              <div >
                 {loading
                     ? "Loading..."
                     : allNewsArticles.length > 0
                         ? allNewsArticles.map((data, index) => {
                            return <NewsCard key={index} data={data} />;
                         })
                         : ""}
              </div>
              </div>
            </Row>
         </Container>
      </div>
   );
};

export default InTheNews;

import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';

const RecentWorks = () => {
    return (
        <div className='recent-works-section mt-5'>
            <Container>
                <h1>Our Recent Work</h1>
                <div className='slider-actions mt-4'>
                    <div className='action-btns'>
                        <button className='arrow-btn me-4'>
                            <svg
                                width='24'
                                height='24'
                                style={{transform: 'rotate(180deg)'}}
                                viewBox='0 0 24 24'
                                fill='currentColor'
                                xmlns='http://www.w3.org/2000/svg'
                            >
                                <path
                                    fillRule='evenodd'
                                    clipRule='evenodd'
                                    d='M4 12C4 11.4477 4.44772 11 5 11L19 11C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13L5 13C4.44772 13 4 12.5523 4 12Z'
                                    //    fill='#1F1A17'
                                />
                                <path
                                    fill-rule='evenodd'
                                    clip-rule='evenodd'
                                    d='M4 12C4 11.4477 4.44772 11 5 11L19 11C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13L5 13C4.44772 13 4 12.5523 4 12Z'
                                    //    fill='inherit'
                                    //    fill-opacity='0.2'
                                />
                                <path
                                    fill-rule='evenodd'
                                    clip-rule='evenodd'
                                    d='M11.2929 4.29289C11.6834 3.90237 12.3166 3.90237 12.7071 4.29289L19.7071 11.2929C20.0976 11.6834 20.0976 12.3166 19.7071 12.7071L12.7071 19.7071C12.3166 20.0976 11.6834 20.0976 11.2929 19.7071C10.9024 19.3166 10.9024 18.6834 11.2929 18.2929L17.5858 12L11.2929 5.70711C10.9024 5.31658 10.9024 4.68342 11.2929 4.29289Z'
                                    //    fill='#1F1A17'
                                />
                                <path
                                    fill-rule='evenodd'
                                    clip-rule='evenodd'
                                    d='M11.2929 4.29289C11.6834 3.90237 12.3166 3.90237 12.7071 4.29289L19.7071 11.2929C20.0976 11.6834 20.0976 12.3166 19.7071 12.7071L12.7071 19.7071C12.3166 20.0976 11.6834 20.0976 11.2929 19.7071C10.9024 19.3166 10.9024 18.6834 11.2929 18.2929L17.5858 12L11.2929 5.70711C10.9024 5.31658 10.9024 4.68342 11.2929 4.29289Z'
                                    //    fill='inherit'
                                    //    fill-opacity='0.2'
                                />
                            </svg>
                        </button>
                        {' '}
                        <button className='arrow-btn me-4'>
                            <svg
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='currentColor'
                                xmlns='http://www.w3.org/2000/svg'
                            >
                                <path
                                    fillRule='evenodd'
                                    clipRule='evenodd'
                                    d='M4 12C4 11.4477 4.44772 11 5 11L19 11C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13L5 13C4.44772 13 4 12.5523 4 12Z'
                                    //    fill='#1F1A17'
                                />
                                <path
                                    fill-rule='evenodd'
                                    clip-rule='evenodd'
                                    d='M4 12C4 11.4477 4.44772 11 5 11L19 11C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13L5 13C4.44772 13 4 12.5523 4 12Z'
                                    //    fill='black'
                                    //    fill-opacity='0.2'
                                />
                                <path
                                    fill-rule='evenodd'
                                    clip-rule='evenodd'
                                    d='M11.2929 4.29289C11.6834 3.90237 12.3166 3.90237 12.7071 4.29289L19.7071 11.2929C20.0976 11.6834 20.0976 12.3166 19.7071 12.7071L12.7071 19.7071C12.3166 20.0976 11.6834 20.0976 11.2929 19.7071C10.9024 19.3166 10.9024 18.6834 11.2929 18.2929L17.5858 12L11.2929 5.70711C10.9024 5.31658 10.9024 4.68342 11.2929 4.29289Z'
                                    //    fill='#1F1A17'
                                />
                                <path
                                    fill-rule='evenodd'
                                    clip-rule='evenodd'
                                    d='M11.2929 4.29289C11.6834 3.90237 12.3166 3.90237 12.7071 4.29289L19.7071 11.2929C20.0976 11.6834 20.0976 12.3166 19.7071 12.7071L12.7071 19.7071C12.3166 20.0976 11.6834 20.0976 11.2929 19.7071C10.9024 19.3166 10.9024 18.6834 11.2929 18.2929L17.5858 12L11.2929 5.70711C10.9024 5.31658 10.9024 4.68342 11.2929 4.29289Z'
                                    //    fill='black'
                                    //    fill-opacity='0.2'
                                />
                            </svg>
                        </button>
                    </div>
                    <Link to='#'>See All</Link>
                </div>
                <Row className='recent-works'>
                    <Col md={6}>
                        <div className='work mt-4'>
                            <h6>SalesLine Salesbook & Inventory Management (www.salesline.click)</h6>
                            <small className='d-block mt-3'>
                                SalesLine Electronic Salesbook and Inventory management Solution eliminate paper-based
                                processes in organizations' sales and inventory management, it manages sales and
                                expenditure information and allows administrators to make well-informed managerial
                                decisions, thereby automating bookkeeping processes
                            </small>
                            <a href="https://salesline.click" target="_blank">
                                <svg
                                    style={{width: '100%', height: 'auto'}}
                                    viewBox='0 0 60 39'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                    <path
                                        fillRule='evenodd'
                                        clipRule='evenodd'
                                        d='M0.249999 19.5003C0.249999 18.945 0.473882 18.4124 0.872397 18.0198C1.27091 17.6271 1.81141 17.4065 2.375 17.4065L52.4952 17.4065L39.1205 4.23266C38.7215 3.83951 38.4973 3.30628 38.4973 2.75028C38.4973 2.19428 38.7215 1.66106 39.1205 1.26791C39.5195 0.874758 40.0607 0.653891 40.625 0.653891C41.1893 0.653891 41.7305 0.874758 42.1295 1.26791L59.1295 18.0179C59.3274 18.2124 59.4844 18.4434 59.5915 18.6978C59.6987 18.9522 59.7538 19.2249 59.7538 19.5003C59.7538 19.7757 59.6987 20.0484 59.5915 20.3027C59.4844 20.5571 59.3274 20.7882 59.1295 20.9827L42.1295 37.7327C41.7305 38.1258 41.1893 38.3467 40.625 38.3467C40.0607 38.3467 39.5195 38.1258 39.1205 37.7327C38.7215 37.3395 38.4973 36.8063 38.4973 36.2503C38.4973 35.6943 38.7215 35.1611 39.1205 34.7679L52.4952 21.594L2.375 21.594C1.81141 21.594 1.27091 21.3734 0.872397 20.9808C0.473882 20.5881 0.249999 20.0556 0.249999 19.5003Z'
                                        fill='#A48243'
                                    />
                                </svg>
                            </a>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className='work mt-4'>
                            <h6>HealthLine Hospital Management Solution</h6>
                            <small className='d-block mt-3'>
                                HealthLine Hospital Management Solution eliminates paper-based processes in health care
                                organizations, manages patient information, and allows doctors, clinicians, and
                                administrators to make well-informed decisions in treatment and hospitalization
                                procedures. In addition, helps health care organizations to automate bookkeeping,
                                patient management, etc
                            </small>
                            <Link to='#'>
                                <svg
                                    style={{width: '100%', height: 'auto'}}
                                    viewBox='0 0 60 39'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                    <path
                                        fillRule='evenodd'
                                        clipRule='evenodd'
                                        d='M0.249999 19.5003C0.249999 18.945 0.473882 18.4124 0.872397 18.0198C1.27091 17.6271 1.81141 17.4065 2.375 17.4065L52.4952 17.4065L39.1205 4.23266C38.7215 3.83951 38.4973 3.30628 38.4973 2.75028C38.4973 2.19428 38.7215 1.66106 39.1205 1.26791C39.5195 0.874758 40.0607 0.653891 40.625 0.653891C41.1893 0.653891 41.7305 0.874758 42.1295 1.26791L59.1295 18.0179C59.3274 18.2124 59.4844 18.4434 59.5915 18.6978C59.6987 18.9522 59.7538 19.2249 59.7538 19.5003C59.7538 19.7757 59.6987 20.0484 59.5915 20.3027C59.4844 20.5571 59.3274 20.7882 59.1295 20.9827L42.1295 37.7327C41.7305 38.1258 41.1893 38.3467 40.625 38.3467C40.0607 38.3467 39.5195 38.1258 39.1205 37.7327C38.7215 37.3395 38.4973 36.8063 38.4973 36.2503C38.4973 35.6943 38.7215 35.1611 39.1205 34.7679L52.4952 21.594L2.375 21.594C1.81141 21.594 1.27091 21.3734 0.872397 20.9808C0.473882 20.5881 0.249999 20.0556 0.249999 19.5003Z'
                                        fill='#A48243'
                                    />
                                </svg>
                            </Link>
                        </div>
                    </Col>
                </Row>
                <Row className='recent-works'>
                    <Col md={6}>
                        <div className='work mt-4'>
                            <h6>Techline Mobile</h6>
                            <small className='d-block mt-3'>
                                Techline Communications has designed and developed
                                various Android, IOS, and web applications and we have a
                                reputation of constantly adding value to organizations
                                in Nigeria and it is with this premise that we propose
                                to development of this app for your esteemed
                                organization. We have a portfolio of Mobile Apps
                                successfully built and deployed to their respective app
                                stores.
                            </small>
                            <Link to='#'>
                                <svg
                                    style={{width: '100%', height: 'auto'}}
                                    viewBox='0 0 60 39'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                    <path
                                        fillRule='evenodd'
                                        clipRule='evenodd'
                                        d='M0.249999 19.5003C0.249999 18.945 0.473882 18.4124 0.872397 18.0198C1.27091 17.6271 1.81141 17.4065 2.375 17.4065L52.4952 17.4065L39.1205 4.23266C38.7215 3.83951 38.4973 3.30628 38.4973 2.75028C38.4973 2.19428 38.7215 1.66106 39.1205 1.26791C39.5195 0.874758 40.0607 0.653891 40.625 0.653891C41.1893 0.653891 41.7305 0.874758 42.1295 1.26791L59.1295 18.0179C59.3274 18.2124 59.4844 18.4434 59.5915 18.6978C59.6987 18.9522 59.7538 19.2249 59.7538 19.5003C59.7538 19.7757 59.6987 20.0484 59.5915 20.3027C59.4844 20.5571 59.3274 20.7882 59.1295 20.9827L42.1295 37.7327C41.7305 38.1258 41.1893 38.3467 40.625 38.3467C40.0607 38.3467 39.5195 38.1258 39.1205 37.7327C38.7215 37.3395 38.4973 36.8063 38.4973 36.2503C38.4973 35.6943 38.7215 35.1611 39.1205 34.7679L52.4952 21.594L2.375 21.594C1.81141 21.594 1.27091 21.3734 0.872397 20.9808C0.473882 20.5881 0.249999 20.0556 0.249999 19.5003Z'
                                        fill='#A48243'
                                    />
                                </svg>
                            </Link>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className='work mt-4'>
                            <h6>EasyTest</h6>
                            <small className='d-block mt-3'>
                                EasyTest Electronic Plug Play Computer-Based Testing
                                Solution automates and simplifies testing, examination,
                                and scoring processes in organizations, schools, and
                                anywhere assessments are administered. It allows
                                administrators make well-informed decisions regarding
                                candidates to be assessed based on their individual test
                                performances
                            </small>
                            <Link to='#'>
                                <svg
                                    style={{width: '100%', height: 'auto'}}
                                    viewBox='0 0 60 39'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                    <path
                                        fillRule='evenodd'
                                        clipRule='evenodd'
                                        d='M0.249999 19.5003C0.249999 18.945 0.473882 18.4124 0.872397 18.0198C1.27091 17.6271 1.81141 17.4065 2.375 17.4065L52.4952 17.4065L39.1205 4.23266C38.7215 3.83951 38.4973 3.30628 38.4973 2.75028C38.4973 2.19428 38.7215 1.66106 39.1205 1.26791C39.5195 0.874758 40.0607 0.653891 40.625 0.653891C41.1893 0.653891 41.7305 0.874758 42.1295 1.26791L59.1295 18.0179C59.3274 18.2124 59.4844 18.4434 59.5915 18.6978C59.6987 18.9522 59.7538 19.2249 59.7538 19.5003C59.7538 19.7757 59.6987 20.0484 59.5915 20.3027C59.4844 20.5571 59.3274 20.7882 59.1295 20.9827L42.1295 37.7327C41.7305 38.1258 41.1893 38.3467 40.625 38.3467C40.0607 38.3467 39.5195 38.1258 39.1205 37.7327C38.7215 37.3395 38.4973 36.8063 38.4973 36.2503C38.4973 35.6943 38.7215 35.1611 39.1205 34.7679L52.4952 21.594L2.375 21.594C1.81141 21.594 1.27091 21.3734 0.872397 20.9808C0.473882 20.5881 0.249999 20.0556 0.249999 19.5003Z'
                                        fill='#A48243'
                                    />
                                </svg>
                            </Link>
                        </div>
                    </Col>
                </Row>
                <Row className='recent-works'>
                    <Col md={6}>
                        <div className='work mt-4'>
                            <h6>PeopleGrid</h6>
                            <small className='d-block mt-3'>
                                PeopleGrid Electronic Human Resource & Account management Solution eliminate paper-based
                                procedures in businesses It is a collection of several different pieces of software that
                                enables managers to make informed choices, automating bookkeeping procedures.
                            </small>
                            <Link to='PeopleGrid'>
                                <svg
                                    style={{width: '100%', height: 'auto'}}
                                    viewBox='0 0 60 39'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                    <path
                                        fillRule='evenodd'
                                        clipRule='evenodd'
                                        d='M0.249999 19.5003C0.249999 18.945 0.473882 18.4124 0.872397 18.0198C1.27091 17.6271 1.81141 17.4065 2.375 17.4065L52.4952 17.4065L39.1205 4.23266C38.7215 3.83951 38.4973 3.30628 38.4973 2.75028C38.4973 2.19428 38.7215 1.66106 39.1205 1.26791C39.5195 0.874758 40.0607 0.653891 40.625 0.653891C41.1893 0.653891 41.7305 0.874758 42.1295 1.26791L59.1295 18.0179C59.3274 18.2124 59.4844 18.4434 59.5915 18.6978C59.6987 18.9522 59.7538 19.2249 59.7538 19.5003C59.7538 19.7757 59.6987 20.0484 59.5915 20.3027C59.4844 20.5571 59.3274 20.7882 59.1295 20.9827L42.1295 37.7327C41.7305 38.1258 41.1893 38.3467 40.625 38.3467C40.0607 38.3467 39.5195 38.1258 39.1205 37.7327C38.7215 37.3395 38.4973 36.8063 38.4973 36.2503C38.4973 35.6943 38.7215 35.1611 39.1205 34.7679L52.4952 21.594L2.375 21.594C1.81141 21.594 1.27091 21.3734 0.872397 20.9808C0.473882 20.5881 0.249999 20.0556 0.249999 19.5003Z'
                                        fill='#A48243'
                                    />
                                </svg>
                            </Link>
                        </div>
                    </Col>
                    {/*<Col md={6}>*/}
                    {/*    <div className='work mt-4'>*/}
                    {/*        <h6>EasyTest</h6>*/}
                    {/*        <small className='d-block mt-3'>*/}
                    {/*            EasyTest Electronic Plug Play Computer-Based Testing*/}
                    {/*            Solution automates and simplifies testing, examination,*/}
                    {/*            and scoring processes in organizations, schools, and*/}
                    {/*            anywhere assessments are administered. It allows*/}
                    {/*            administrators make well-informed decisions regarding*/}
                    {/*            candidates to be assessed based on their individual test*/}
                    {/*            performances*/}
                    {/*        </small>*/}
                    {/*        <Link to='#'>*/}
                    {/*            <svg*/}
                    {/*                style={{width: '100%', height: 'auto'}}*/}
                    {/*                viewBox='0 0 60 39'*/}
                    {/*                fill='none'*/}
                    {/*                xmlns='http://www.w3.org/2000/svg'*/}
                    {/*            >*/}
                    {/*                <path*/}
                    {/*                    fillRule='evenodd'*/}
                    {/*                    clipRule='evenodd'*/}
                    {/*                    d='M0.249999 19.5003C0.249999 18.945 0.473882 18.4124 0.872397 18.0198C1.27091 17.6271 1.81141 17.4065 2.375 17.4065L52.4952 17.4065L39.1205 4.23266C38.7215 3.83951 38.4973 3.30628 38.4973 2.75028C38.4973 2.19428 38.7215 1.66106 39.1205 1.26791C39.5195 0.874758 40.0607 0.653891 40.625 0.653891C41.1893 0.653891 41.7305 0.874758 42.1295 1.26791L59.1295 18.0179C59.3274 18.2124 59.4844 18.4434 59.5915 18.6978C59.6987 18.9522 59.7538 19.2249 59.7538 19.5003C59.7538 19.7757 59.6987 20.0484 59.5915 20.3027C59.4844 20.5571 59.3274 20.7882 59.1295 20.9827L42.1295 37.7327C41.7305 38.1258 41.1893 38.3467 40.625 38.3467C40.0607 38.3467 39.5195 38.1258 39.1205 37.7327C38.7215 37.3395 38.4973 36.8063 38.4973 36.2503C38.4973 35.6943 38.7215 35.1611 39.1205 34.7679L52.4952 21.594L2.375 21.594C1.81141 21.594 1.27091 21.3734 0.872397 20.9808C0.473882 20.5881 0.249999 20.0556 0.249999 19.5003Z'*/}
                    {/*                    fill='#A48243'*/}
                    {/*                />*/}
                    {/*            </svg>*/}
                    {/*        </Link>*/}
                    {/*    </div>*/}
                    {/*</Col>*/}
                </Row>
            </Container>
        </div>
    );
};

export default RecentWorks;
